// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
    --sidebar-bg: #f8f9fa;
    --sidebar-color: #000;
    --sidebar-link-color: #000;
    --sidebar-link-hover-bg: #e9ecef;
    --sidebar-link-hover-color: #000;
}

@media (prefers-color-scheme: dark) {
    :root {
        --sidebar-bg: #343a40;
        --sidebar-color: #fff;
        --sidebar-link-color: #fff;
        --sidebar-link-hover-bg: #495057;
        --sidebar-link-hover-color: #fff;
    }
}

.app-container {
    height: 100vh;
    display: flex;
    flex-direction: column;
}

.content-container {
    flex: 1;
    padding: 20px;
    transition: margin-left 0.3s;
}
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;IACI,qBAAqB;IACrB,qBAAqB;IACrB,0BAA0B;IAC1B,gCAAgC;IAChC,gCAAgC;AACpC;;AAEA;IACI;QACI,qBAAqB;QACrB,qBAAqB;QACrB,0BAA0B;QAC1B,gCAAgC;QAChC,gCAAgC;IACpC;AACJ;;AAEA;IACI,aAAa;IACb,aAAa;IACb,sBAAsB;AAC1B;;AAEA;IACI,OAAO;IACP,aAAa;IACb,4BAA4B;AAChC","sourcesContent":[":root {\n    --sidebar-bg: #f8f9fa;\n    --sidebar-color: #000;\n    --sidebar-link-color: #000;\n    --sidebar-link-hover-bg: #e9ecef;\n    --sidebar-link-hover-color: #000;\n}\n\n@media (prefers-color-scheme: dark) {\n    :root {\n        --sidebar-bg: #343a40;\n        --sidebar-color: #fff;\n        --sidebar-link-color: #fff;\n        --sidebar-link-hover-bg: #495057;\n        --sidebar-link-hover-color: #fff;\n    }\n}\n\n.app-container {\n    height: 100vh;\n    display: flex;\n    flex-direction: column;\n}\n\n.content-container {\n    flex: 1;\n    padding: 20px;\n    transition: margin-left 0.3s;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
